import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
import 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';


ReactDOM.render(<App />, document.getElementById('root'))
